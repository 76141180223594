<template>
  <div class="annualLeaveDetailBody">
    <PocCardTable class="annualLeaveDetail">
<!--      <template v-slot:headerTitle>Annual Leave Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">Annual Leave Repo</div>
          <div>
            <el-button @click="search" type="primary">查询</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="goback">返回</el-button>
          </div>
        </div>
      </template>

      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="地区">
            <el-select
              v-model="formInline.location"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in locations"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="组织单元">
            <el-select v-model="formInline.org" clearable filterable placeholder="请选择">
              <el-option
                v-for="item in orgs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="职位">
            <el-select
              v-model="formInline.jobTitle"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in jobTitles"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="薪资组">
            <el-select
              v-model="formInline.salaryGroup"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in salaryGroups"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="CS ID" label-position="top">
            <el-input placeholder="" v-model="formInline.csId"> </el-input>
          </el-form-item>

          <el-form-item label="employeeName" label-position="top">
            <el-input placeholder="" v-model="formInline.employeeName">
            </el-input>
          </el-form-item>

          <!-- <el-form-item label="Chinese Name" label-position="top">
            <el-input placeholder="" v-model="formInline.chineseName">
            </el-input>
          </el-form-item> -->
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable style="margin-top: 20px">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="maxHeight"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { annualLeaveDetailRepoTableColumn } from '@/views/payroll/TableColumn'
import {
  getPayrollLocationList,
  getPayrollOrgList,
  getPayrollJobTitleList,
  getPayrollAnnualSalaryGroupList,
  getPayrollAnnualDetailList
} from '@/api/payroll'
import { payrollAnnualExport } from '@/api/payrollReport'

import PocSlider from '@/components/poc-slider/poc-slider'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: annualLeaveDetailRepoTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        csId: '',
        employeeName: '',
        company: '',
        location: '',
        org: '',
        jobTitle: '',
        slaryGroup: '',
        pageSize: 10,
        pageNum: 1,
        periodUploadRecordId: ''
      },
      locations: [],
      orgs: [],
      jobTitles: [],
      salaryGroups: [],
      targetObj: '',
      toObj: '',
      periodDialogVisible: false,
      newPeriod: '',
      formStatus: false,
      periodRange: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 400
    this.formInline.periodUploadRecordId = this.$route.query.id
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getPayrollLocationList().then((res) => {
        this.locations = res.data
      })

      getPayrollOrgList().then((res) => {
        this.orgs = res.data
      }),
        getPayrollJobTitleList().then((res) => {
          this.jobTitles = res.data
        }),
        getPayrollAnnualSalaryGroupList().then((res) => {
          this.salaryGroups = res.data
        })
    },

    search() {
      getPayrollAnnualDetailList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
      })
    },

    queryApi() {
      this.search()
    },

    selectPersonal(val1, val2, val3) {
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    createPeriod() {
      this.periodDialogVisible = true
    },

    handleSelectionChange() {},

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    download() {
      payrollAnnualExport(this.formInline)
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll',
        query: {
          id: 'annualLeaveReport',
          title: 'annualLeaveReport'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.annualLeaveDetailBody {
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .annualLeaveDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
